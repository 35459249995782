import { useState, useEffect } from "react";
import { ReactComponent as ScrollLeft } from "../../assets/svg/scroll-left-larger.svg";
import { ReactComponent as ScrollRight } from "../../assets/svg/scroll-right-larger.svg";
import "./testimonials.scss";

export function Testimonials({ setBlueBg }: any) {
  const [index, setIndex] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(false);

    setTimeout(() => {
      setShow(true);
    }, 0.1);
  }, [index]);

  const testimonies = [
    {
      content:
        "I searched the internet for a solution to help me consolidate my investments online and give access to my family in case I'm not around, then Cova came.",
      author: "Emmanuel, San Jose, USA.",
    },
    {
      content:
        "From real estate assets to stocks, 401k, bank accounts, and brokerage accounts, Cova gives me a clear view of all finances and the peace of mind that nothing will fall within the cracks in the event of any of life's emergencies.",
      author: "Seth Atham, Maryland, USA.",
    },
    {
      content:
        "I like how features are being driven by real life issues that occur when there’s no transparency in your personal financial position.",
      author: "Idiare, Lagos, Nigeria.",
    },
  ];

  return (
    <div className={`testimonies-section ${setBlueBg ? "blue-bg231" : ""}`}>
      <div className="title911" data-aos="fade-up" data-aos-duration="1000">
        <div className="t91-one">What our users are saying</div>
        <div className="t91-two">Our Testimonials</div>
      </div>

      <div className="testimonies-wrapper">
        <div className="testimonies-main">
          <div
            className={`c-btn click ${index === 0 ? "disabled" : ""}`}
            onClick={() => {
              if (index > 0) {
                setIndex(index - 1);
              }
            }}
          >
            <ScrollLeft />
          </div>

          <div className="testimonies">
            {show && (
              <div className="testimony" data-aos="fade" data-aos-duration="1500">
                <div className="text101">
                  <span>“</span>
                  {testimonies[index]?.content}
                  <span>”</span>
                </div>
                <div className="user101">{testimonies[index]?.author}</div>
              </div>
            )}
          </div>

          <div
            className={`c-btn click ${index === testimonies.length - 1 ? "disabled" : ""}`}
            onClick={() => {
              if (index < 2) {
                setIndex(index + 1);
              }
            }}
          >
            <ScrollRight />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import "../auth/auth.scss";
import { HeadexComponent } from "../../components/header/headex";
import { FooterComponent } from "../../components/footer";
import { ComingSoonComponent } from "../../components/comingsoon";
import { Helmet } from "react-helmet";

export const TermsHeaderComponent = (props: any) => (
  <h2 className="terms_head" {...props}>
    {props.children}
  </h2>
);

export const TermsTextBodyComponent = (props: any) => (
  <div className="terms_body" {...props}>
    {props.children}
  </div>
);

const TermsListBodyComponent = (props: any) => (
  <ul className="terms_body" {...props}>
    {props.children}
  </ul>
);

export const TermsContainer = (props: any) =>
  props.security ? (
    <div className="terms_container">{props.children}</div>
  ) : (
    <li className="terms_container">{props.children}</li>
  );

const CookiePolicyPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="authpage no-border">
      <Helmet>
        <meta name="description" content="Learn more about Cova's term and conditions" />
        <title>Terms and Conditions</title>
        <meta property="og:title" content="Terms and Conditions" />
        <meta property="og:description" content="Learn more about Cova's term and conditions" />
        <meta property="og:url" content="https://www.getcova.com/terms-and-condition" />
      </Helmet>
      <HeadexComponent />
      <div className="terms_page">
        <div className="terms_intro">
          <div>
            <div className="terms_container">
              <h1>
                <span>Cookie</span>&nbsp;<span>Policy</span>
              </h1>
            </div>
          </div>
        </div>
        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent>Definitions and key terms</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                To help explain things as clearly as possible in this Cookie Policy, every time any
                of these terms are referenced, are strictly defined as:
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Cookie:</span> small amount of data
                generated by a website and saved by your web browser. It is used to identify your
                browser, provide analytics, remember information about you such as your language
                preference or login information.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Company:</span> when this policy mentions
                “Company,” “we,” “us,” or “our,” it refers to Cova, that is responsible for your
                information under this Cookie Policy.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Device:</span> any internet connected
                device such as a phone, tablet, computer or any other device that can be used to
                visit Cova and use the services.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Personal Data:</span> any information that
                directly, indirectly, or in connection with other information — including a personal
                identification number — allows for the identification or identifiability of a
                natural person.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Service:</span> refers to the service
                provided by Cova as described in the relative terms (if available) and on this
                platform.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Third-party service:</span> refers to
                advertisers, contest sponsors, promotional and marketing partners, and others who
                provide our content or whose products or services we think may interest you.
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; Website:</span> Cova."’s" site, which can
                be accessed via{" "}
                <span>
                  <a href="https://getcova.com" rel="noopener noreferrer">
                    getcova.com
                  </a>
                </span>
              </p>
              <br />
              <p>
                <span style={{ fontWeight: 500 }}>&#8226; You:</span> a person or entity that is
                registered with Cova to use the Services.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Introduction</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                This Cookie Policy explains how Cova and its affiliates (collectively "Cova", "we",
                "us", and "ours"), use cookies and similar technologies to recognize you when you
                visit our website, including without limitation www.getcova.com and any related
                URLs, mobile or localized versions and related domains / sub-domains ("Websites").
                It explains what these technologies are and why we use them, as well as the choices
                for how to control them.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>What is a cookie?</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                A cookie is a small text file that is stored on your computer or other internet
                connected device in order to identify your browser, provide analytics, remember
                information about you such as your language preference or login information. They're
                completely safe and can't be used to run programs or deliver viruses to your device.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Why do we use cookies?</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We use first party and/or third party cookies on our website for various purposes
                such as:
              </p>
              <TermsListBodyComponent>
                <li>To facilitate the operation and functionality of our website;</li>
                <li>
                  To improve your experience of our website and make navigating around them quicker
                  and easier;
                </li>
                <li>
                  To allow us to make a bespoke user experience for you and for us to understand
                  what is useful or of interest to you;
                </li>
                <li>To analyze how our website is used and how best we can customize it;</li>
                <li>
                  To identify future prospects and personalize marketing and sales interactions with
                  it;
                </li>
                <li>To facilitate the tailoring of online advertising to your interests.</li>
              </TermsListBodyComponent>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>What type of cookies does Cova use?</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Cookies can either be session cookies or persistent cookies. A session cookie
                expires automatically when you close your browser. A persistent cookie will remain
                until it expires or you delete your cookies. Expiration dates are set in the cookies
                themselves; some may expire after a few minutes while others may expire after
                multiple years. Cookies placed by the website you’re visiting are called “first
                party cookies”.
              </p>
              <br />
              <p>
                Strictly Necessary cookies are necessary for our website to function and cannot be
                switched off in our systems. They are essential in order to enable you to navigate
                around the website and use its features. If you remove or disable these cookies, we
                cannot guarantee that you will be able to use our website.
              </p>
              <br />
              <p>We use the following types of cookies in our website:</p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Essential Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We use essential cookies to make our website work. These cookies are strictly
                necessary to enable core functionality such as security, network management, your
                cookie preferences and accessibility. Without them you wouldn't be able to use basic
                services. You may disable these by changing your browser settings, but this may
                affect how the Websites function.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Performance and Functionality Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                These cookies are used to enhance the performance and functionality of our website
                but are non-essential to their use. However, without these cookies, certain
                functionality like videos may become unavailable or you would be required to enter
                your login details every time you visit the website as we would not be able to
                remember that you had logged in previously.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Marketing Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                These account-based marketing cookies enable us to identify future prospects and
                personalize sales and marketing interactions with them.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Analytics and Customization Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                These cookies collect information that is used to help us understand how our website
                is being used or how effective our marketing campaigns are, or to help us customize
                our website for you.
              </p>
              <br />
              <p>
                We use cookies served by Google Analytics to collect limited data directly from
                end-user browsers to enable us to better understand your use of our website. Further
                information on how Google collects and uses this data can be found{" "}
                <span>
                  <a
                    href="https://www.google.com/policies/privacy/partners/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </span>
                . You can opt-out of all Google supported analytics on our Websites by visiting this{" "}
                <span>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    page
                  </a>
                </span>
                .
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Advertising Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                These cookies collect information over time about your online activity on the
                website and other online services to make online advertisements more relevant and
                effective to you. This is known as interest-based advertising. They also perform
                functions like preventing the same ad from continuously reappearing and ensuring
                that ads are properly displayed for advertisers. Without cookies, it’s really hard
                for an advertiser to reach its audience, or to know how many ads were shown and how
                many clicks they received.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Social Media Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                These cookies are used when you share information using a social media sharing
                button or “like” button on our Site or you link your account or engage with our
                content on or through a social networking website such as Facebook, Twitter or
                Google+. The social network will record that you have done this. These cookies may
                also include certain code that has been placed on the platform to help track
                conversions from ads, optimize ads based on collected data, build targeted audiences
                for future ads, and remarket to qualified users who have already taken certain
                action on the platform.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Third Party Cookies</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Some cookies that have been set on our website are not set on a first party basis by
                Cova. The Websites can be embedded with content from third parties to serve
                advertising. These third party service providers may set their own cookies on your
                web browser. Third party service providers control many of the performance and
                functionality, advertising, marketing and analytics cookies described above. We do
                not control the use of these third party cookies as cookies can only be accessed by
                the third party that originally set them.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>How you can manage cookies?</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Most browsers allow you to control cookies through their 'settings' preferences.
                However, if you limit the ability of websites to set cookies, you may worsen your
                overall user experience, since it will no longer be personalized to you. It may also
                stop you from saving customized settings like login information. Browser
                manufacturers provide help pages relating to cookie management in their products.
              </p>
              <br />
              <p>
                Browser manufacturers provide help pages relating to cookie management in their
                products. Please see below for more information.
              </p>
              <TermsListBodyComponent>
                <li>Google Chrome</li>
                <li>Internet Explorer</li>
                <li>Mozilla Firefox</li>
                <li>Safari (Desktop)</li>
                <li>Safari (Mobile)</li>
                <li>Android Browser</li>
                <li>Opera</li>
                <li>Opera Mobile</li>
              </TermsListBodyComponent>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>
              Blocking and disabling cookies and similar technologies
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Wherever you're located you may also set your browser to block cookies and similar
                technologies, but this action may block our essential cookies and prevent our
                website from functioning properly, and you may not be able to fully utilize all of
                its features and services. You should also be aware that you may also lose some
                saved information (e.g. saved login details, site preferences) if you block cookies
                on your browser. Different browsers make different controls available to you.
                Disabling a cookie or category of cookie does not delete the cookie from your
                browser, you will need to do this yourself from within your browser, you should
                visit your browser's help menu for more information.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Changes To Our Cookie Policy</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We may change our Service and policies, and we may need to make changes to this
                Cookie Policy so that they accurately reflect our Service and policies. Unless
                otherwise required by law, we will notify you (for example, through our Service)
                before we make changes to this Cookie Policy and give you an opportunity to review
                them before they go into effect. Then, if you continue to use the Service, you will
                be bound by the updated Cookie Policy. If you do not want to agree to this or any
                updated Cookie Policy, you can delete your account.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Your Consent</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                By using our website, registering an account, or making a purchase, you hereby
                consent to our Cookie Policy and agree to its terms.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Contact Us</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Don't hesitate to contact us if you have any questions regarding our Cookie Policy.
              </p>
              <p>
                via <a href="mailto:support@getcova.com">support@getcova.com</a>.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <div className="terms_container">
            <TermsTextBodyComponent>
              <p>This document was last updated on 12th of December, 2021</p>
            </TermsTextBodyComponent>
          </div>
        </ul>
        <ComingSoonComponent />
      </div>
      <FooterComponent />
    </div>
  );
};

export default CookiePolicyPage;

export const questions = [
  {
    question: "Can my loved ones discover my Cryptocurrency assets in the event of an emergency?",
    answer:
      "Over 25% of Bitcoin is lost due to the owners passing away without notifying family members of their existence or how to access the coins.",
  },
  {
    question: "Can my family member discover my assets if anything happens to me?",
    answer:
      "There are over $80b worth of unclaimed assets in the United States. It’s important to ensure everything you own can be discovered by your loved ones in the event of death.",
  },
  {
    question: "How do I increase my family’s net worth?",
    answer:
      "Building up multiple assets, cutting debt, paying loans, and limiting liabilities increase your overall net worth. In addition, tracking your wealth and planning your estate helps you to preserve your family’s wealth. ",
  },
  {
    question: "What’s my Net worth?",
    answer:
      "Knowing your net worth can be as simple as signing into your Cova dashboard, where your assets and liabilities are tracked and your net worth calculated. No complex sheets.",
  },
  {
    question: "What’s the market value of my property?",
    answer:
      "The property market is ever-changing; whether looking to sell your property or just wanting to know the worth of your property and how it impacts your net worth, a quick login into your Cova account and connecting searching your property address with the real estate tracking tool provides the current market value of any property across the united states. ",
  },
  {
    question: "Can my spouse see everything we’ve built together?",
    answer:
      "It’s important for spouses to plan their finances together. This begins with the ability to keep track of assets, liabilities, and every other aspect of their financial health together.",
  },
  {
    question: "How do I manage all my financial documents?",
    answer:
      "Managing your financial and business documents alongside your assets is more efficient. You can then track your wealth and associated documents in one place. That's what the Cova vault helps you do with your financial documents.",
  },
  {
    question: "How do I keep track of all my assets?",
    answer:
      "Excel sheets can no longer perform the diverse functions of keeping track of everything owned or owed. This is where an intelligent tool like Cova comes in.",
  },
  {
    question: "How do I ensure my assets don’t become unclaimed assets if anything happens to me?",
    answer:
      "Life happens; planning with Cova gives peace of mind. Collaborate with family members and advisors in real-time, or set up Lifecheck to share your financial data with family members if the unexpected happens to you.",
  },
  {
    question: "Can my spouse find my insurance policies if I die?",
    answer:
      "Several family members cannot access life insurance policies to which they are beneficiaries because of a lack of information. Cova’s Lifecheck can help notify and share financial information with your family if anything happens to you.",
  },
];

import { useRef } from "react";
import { ReactComponent as ScrollRightBtn } from "../../assets/svg/scroll-right.svg";
import { ReactComponent as Polygon } from "../../assets/svg/polygon.svg";
import { questions } from "../../data/questions";
import "./foryou.scss";

const ForYou = () => {
  const scrollRef: any = useRef();

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      scrollRef.current.scrollLeft -= 300;
    } else {
      scrollRef.current.scrollLeft += 300;
    }
  };

  return (
    <section className="for-you">
      <div className="for-you-wrapper">
        <div className="for-you-title">
          Ever asked any of these questions? <br />
          <span>Cova is for you.</span>
        </div>

        <div className="for-you-main" ref={scrollRef}>
          {questions.map((item: { question: string; answer: string }, index: number) => (
            <div className="q-item" key={index}>
              <div className="q-i-question">{item?.question}</div>

              <div className="q-i-answer">
                <Polygon className="a-polygon" />
                <div>{item?.answer}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="scroll-btns no-select">
          <div>
            <ScrollRightBtn
              className="sb-left click"
              onClick={() => {
                handleScroll("left");
              }}
            />
          </div>

          <div>
            <ScrollRightBtn
              className="click"
              onClick={() => {
                handleScroll("");
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForYou;

import { useState, useEffect } from "react";
import { ReactComponent as Whitetick } from "../../assets/svg/whitetick.svg";
import { Helmet } from "react-helmet";
import MainButton from "../../components/buttons/mainButton";
import "../auth/auth.scss";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoaderContext } from "../../contexts/loader";
import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";
import { toast } from "react-toastify";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Textinput } from "../../components/inputs/textinput";
import { ReactComponent as Vector } from "../../assets/svg/onboarding-vector.svg";
import { ReactComponent as Vector2 } from "../../assets/svg/onboarding-vector-2.svg";
import { VerifyAccountResponse } from "../verify-email";
import { ApolloError, useMutation } from "@apollo/client";
import { UPDATE_USERDATA } from "../../graphql/auth";

export type updateUserdataInput = {
  about_us?: string;
  coupon?: string;
  first_name?: string;
  last_name?: string;
};

export type CuerrentUser = {
  about_us: string | null;
  coupon: string | null;
  email: string;
  first_name: string;
  id: number;
  image_url: string | null;
  is_referred: boolean | null;
  is_verified: boolean;
  last_name: string;
  phone_number: string;
  signup_date: Date;
};

const Plans = ({ text, selected, click }: any) => {
  return (
    <div
      className={`deck-other ${selected ? "deck-selected" : "deck-unselected"}  deck-normal`}
      onClick={click}
    >
      <div className={`select-circle ${selected ? "circle-selected" : "circle-unselected"}`}>
        {selected && <Whitetick />}
      </div>
      <div className="deck-text">{text}</div>
    </div>
  );
};

const responses = [
  "Referred by a friend",
  "Twitter",
  "Google Ads",
  "Quora Ads",
  "Instagram",
  "Facebook",
  "Instagram/Facebook Ads",
  "TikTok",
  "Discount Offer",
];

export default function Success() {
  const [select, setSelect] = useState<string>("");
  const [otherInput, setOtherInput] = useState<string>("");
  const navigate = useNavigate();
  const [, setShowLoader] = useContext(LoaderContext);
  const [, setFirstName] = useLocalStorage("firstName", "");
  const [referralInput, setReferralInput] = useState<string>("");
  const [userdata, setUserdata] = useState<VerifyAccountResponse | null>(null);

  useEffect(() => {
    // @ts-expect-error
    if (window?.gtag && process.env.REACT_APP_DEPLOY_ENV !== "beta") {
      // @ts-expect-error
      gtag("event", "conversion", { send_to: "AW-10786098302/dInQCNzR4L8DEP6Ym5co" });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getUserdata = localStorage.getItem("userdata");

    if (getUserdata) {
      setUserdata(JSON.parse(getUserdata));
    }
  }, []);

  const [updateUserdata, { data: updateUserdataResponse }] = useMutation(UPDATE_USERDATA, {
    context: {
      headers: {
        authorization: `Bearer ${userdata?.accessToken}`,
      },
    },
    errorPolicy: "all",
    onCompleted: () => {
      const data: { updateUserData: CuerrentUser } = updateUserdataResponse;

      if (data.updateUserData) {
        // @ts-expect-error
        if (window?.fbq) {
          // @ts-expect-error
          fbq("track", "CompleteRegistration");
        }

        setFirstName(data.updateUserData.first_name);
        localStorage.removeItem("tsrc");
        navigate(
          `/chose-plan${data.updateUserData.coupon ? `?coupon=${data.updateUserData.coupon}` : ""}`
        );
      } else {
        toast.error("Something went wrong");
      }

      setShowLoader(false);
    },
    onError(error: ApolloError) {
      setShowLoader(false);
      toast.error(error?.message ?? "Something went wrong");
    },
  });

  const handleSubmit = () => {
    setShowLoader(true);

    const updateDataInput: updateUserdataInput = {
      coupon: referralInput,
      about_us: otherInput ? otherInput : select,
    };

    updateUserdata({
      variables: {
        updateDataInput,
      },
    });
  };

  return (
    <div className="success-page-container">
      <Helmet>
        <title>How did you hear about our company?</title>
        <meta
          name="description"
          content="To help Cova personalize your needs, please select the correct option."
        />
        <meta property="og:title" content="How did you hear about  our company?" />
        <meta
          property="og:description"
          content="To help Cova personalize your needs, please select the correct option."
        />
        <meta property="og:url" content={`${process.env.REACT_APP_HOME}success`} />
      </Helmet>
      <div className="app-logo">
        <Logo />
        {process.env.REACT_APP_DEPLOY_ENV === "beta" && <p className="beta-flag">Beta</p>}
      </div>

      <div className="subscription-container">
        <div className="form-container">
          <Vector className="o-vect top" />
          <Vector2 className="o-vect bottom" />

          <div className="f-c-inner">
            <div className="thankyou-sub">
              Hi there! We are glad to have you here, could you please tell us:
            </div>
            <div className="thankyou-header">How did you hear about our company?</div>

            <div className="r-code-input321">
              <Textinput
                onChange={(e: any) => {
                  setReferralInput(e.target.value);
                }}
                value={referralInput}
                label="Have a Referral Code? (Optional)"
                type="text"
                inputid="referral"
                placeholder="EMMA50"
                // iserror={errors.referral}
                message={"Please provide a correct referral code."}
              />
            </div>

            {responses.map((item) => (
              <Plans
                key={item}
                text={item}
                click={() => {
                  setSelect(item);
                  setOtherInput("");
                }}
                selected={item === select}
              />
            ))}

            <div className="other-title">Other</div>

            <textarea
              value={otherInput}
              onChange={(e: any) => {
                setOtherInput(e.target.value);
                setSelect("");
              }}
              className="other-input"
              rows={2}
              placeholder="Any other way you got to know about us?"
            />

            <div className="btn-wrapper-z">
              <MainButton
                size="small"
                type="primary"
                disabled={!select && !otherInput}
                extraClasses="thankyou-button"
                click={() => {
                  handleSubmit();
                }}
              >
                Continue
              </MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./getstarted.scss";
import { Link } from "react-router-dom";

export const GetStartedComponent = ({ theme }: { theme: string }) => {
  return (
    <>
      <div
        id="ready-to-get-started"
        className={`ready-to-get-started ${theme === "blue" ? "blue" : ""}`}
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className={theme === "blue" ? "blue" : ""}>
          <div className="ready-to-get-started-1">
            <p className={`ready-to-get-started-ready ${theme === "blue" ? "blue" : ""}`}>
              Plan for the long-term
            </p>
            <p className={`ready-to-get-started-start ${theme === "blue" ? "blue" : ""}`}>
              Start organizing your assets, track your net worth, build your digital vault. Take
              control of your wealth today.
            </p>
          </div>
          <div className="ready-to-get-started-2">
            <Link to="/signup" className={`getstarted-white ${theme === "blue" ? "blue" : ""}`}>
              Get started for free
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

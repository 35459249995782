import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeadexComponent } from "../../components/header/headex";
import { FooterComponent } from "../../components/footer";
import { TermsContainer, TermsHeaderComponent, TermsTextBodyComponent } from "./termsandcondition";
import { Helmet } from "react-helmet";
import "./auth.scss";

const EulaPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="authpage no-border">
      <Helmet>
        <meta name="description" content="Read more about Cova's End User License Agreement" />
        <title>Cova | End User License Agreement</title>
        <meta property="og:title" content="Cova | End User License Agreement" />
        <meta
          property="og:description"
          content="Read more about Cova's End User License Agreement"
        />
        <meta property="og:url" content="https://www.getcova.com/eula" />
      </Helmet>
      <HeadexComponent />
      <div className="terms_page">
        <div className="terms_intro">
          <div>
            <div className="terms_container">
              <h1>
                <span>Cova's</span>&nbsp;<span>End User License Agreement</span>
              </h1>
              <p>
                This End User License Agreement ("EULA") is a binding legal agreement between you,
                as an individual or entity, and Cova. By downloading, installing, or using this
                application for Android or iOS as applicable (the "Software"), you agree to be bound
                by the terms of this EULA. If you do not agree to this EULA, do not use the Software
                and delete it. You agree that installation or use of the Software signifies that you
                have read, understood, and agree to be bound by this EULA. For clarification, any
                Cova Inc. service (“Cova Inc. Service”) accessed through the Software, or otherwise,
                is also subject to the Cova Inc.{" "}
                <span className="link-itm">
                  <Link to="/terms-and-condition">User Agreement</Link>
                </span>
                ,{" "}
                <span className="link-itm">
                  <Link to="/privacy">Privacy Policy</Link>
                </span>{" "}
                and{" "}
                <span className="link-itm">
                  <Link to="/cookie-policy">Cookie Policy</Link>
                </span>{" "}
                . Additional terms may apply to specific features. The Software is provided to you
                under this EULA solely for your private, non-commercial use. Use of the Software or
                any Cova Inc. Service within an organization requires a commercial agreement for the
                applicable Cova Inc. Service.
              </p>
              <br />
              <p>Last revised on November 22, 2021</p>
            </div>
          </div>
        </div>
        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent> Description of Software</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                The Software is a downloadable software application that enables you to access Cova
                Inc. functionality directly from your Android, iPhone, iPad or other mobile device
                supported by Cova Inc. (“Device”). You may download the Software whether or not you
                use the Cova Inc. Service, but you must associate it with your Cova Inc. account to
                enable its full functionality.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>License</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Cova Inc. hereby grants you, subject to the terms and conditions of this EULA, a
                non-exclusive, non-transferable personal license to:
              </p>
              <ul className="roman">
                <li>
                  <p>Use the Software for your own personal use;</p>
                </li>

                <li>
                  <p>Install the Software on only one Device; and</p>
                </li>

                <li>
                  <p>
                    Make one copy of the Software in any machine-readable form solely for backup
                    purposes, provided you reproduce the Software in its original form and with all
                    proprietary notices on the backup copy.
                  </p>
                </li>
              </ul>
              <br />
              <p>
                For clarity, the foregoing is not intended to prohibit you from installing and
                backing up the Software for another Device on which you also agreed to the EULA.
                Each instance of this EULA that you agree to grants you the aforementioned rights in
                connection with the installation, use and backup of one copy of the Software on one
                Device.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Title</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Title, ownership and all rights (including without limitation intellectual property
                rights) in and to the Software shall remain with Cova Inc.. Except for those rights
                expressly granted in this EULA, no other rights are granted, whether express or
                implied.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Restrictions</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                You understand and agree that you shall only use the Software in a manner that
                complies with any and all applicable laws in the jurisdictions in which you use the
                Software. Your use shall be in accordance with applicable restrictions concerning
                privacy and intellectual property rights.
                <br />
                <br />
                You may not:
              </p>
              <ul className="roman">
                <li>Create derivative works based on the Software;</li>

                <li>Use the Software for any purpose other than as described herein;</li>
                <li>Copy or reproduce the Software except as described in this EULA;</li>
                <li>
                  Sell, assign, license, disclose, distribute or otherwise transfer or make
                  available the Software or any copies of the Software in any form to any third
                  parties;
                </li>

                <li>
                  Alter, translate, decompile, reverse assemble or reverse engineer the Software, or
                  attempt to do any of the foregoing, except to the extent this prohibition is not
                  permitted under applicable law; or
                </li>
                <li>Remove or alter any proprietary notices or marks on the Software.</li>
              </ul>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Personal Information and Privacy</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Our handling of personal information we collect through the Cova Inc. Services or
                the Software is governed by the{" "}
                <span className="link-itm">
                  <Link to="/privacy">Cova Inc. Privacy Policy</Link>
                </span>
                .
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>No Warranty</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                COVA INC. DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE WILL MEET
                ANY REQUIREMENTS OR NEEDS YOU MAY HAVE, OR THAT THE SOFTWARE WILL OPERATE
                ERROR-FREE, OR IN AN UNINTERRUPTED MANNER, OR THAT ANY DEFECTS OR ERRORS WILL BE
                CORRECTED, OR THAT THE SOFTWARE IS FULLY COMPATIBLE WITH ANY PARTICULAR PLATFORM.
                THE SOFTWARE IS OFFERED ON AN "AS-IS" BASIS AND NO WARRANTY, EITHER EXPRESS OR
                IMPLIED, IS GIVEN. Cova Inc. EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME
                JURISDICTIONS DO NOT ALLOW THE WAIVER OR EXCLUSION OF IMPLIED WARRANTIES SO THEY MAY
                NOT APPLY TO YOU.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Right to Terminate or Modify Software</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Cova Inc. may modify the Software and this EULA without notice. You may cease use of
                the Software at any time. Either party may terminate this EULA at any time, with or
                without notice.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>U.S. Government Restricted Rights</TermsHeaderComponent>
            <TermsTextBodyComponent>
              By accepting the delivery, the government agrees that the Software and accompanying
              documentation qualify as “commercial” computer software within the meaning of the
              applicable acquisition regulations. The terms and conditions of this EULA govern the
              government's use and disclosure of the Software and supersede any conflicting terms
              and conditions. If this EULA fails to meet the government's needs or is inconsistent
              in any way with federal law, the government must return the Software, unused, to Cova
              Inc..
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Indemnification</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                To the fullest extent permitted by law, you agree to indemnify and otherwise hold
                harmless Cova Inc. Corporation, its officers, employees, agents, subsidiaries,
                affiliates and other partners from any direct, indirect, incidental, special,
                consequential or exemplary damages arising out of, relating to, or resulting from
                your use of the Software or any other matter relating to the Software.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Limitation of Liability</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT COVA INC. SHALL NOT BE LIABLE FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT
                LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                LOSSES (EVEN IF Cova Inc. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). IN
                NO EVENT WILL Cova Inc.'S AGGREGATE LIABILITY TO YOU EXCEED THE AMOUNT OF LICENSING
                FEES PAID BY YOU TO COVA INC. THESE LIMITATIONS AND EXCLUSIONS WILL APPLY
                NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. SOME
                JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
                MAY NOT APPLY TO YOU.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Export Restrictions</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                This EULA is expressly made subject to any laws, regulations, orders or other
                restrictions on the export of software from the United States of America, and may be
                subject to export and import regulations of other countries. You acknowledge and
                agree not to import, export, re-export, transfer or use, directly or indirectly, the
                Software without compliance with such laws, regulations, orders or other
                restrictions.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>General</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                This EULA constitutes the entire agreement between you and Cova Inc. regarding the
                Software. If any provision of this EULA is held by a court of competent jurisdiction
                to be contrary to law, such provision will be changed and interpreted so as to best
                accomplish the objectives of the original provision to the fullest extent allowed by
                law and the remaining provisions of this EULA will remain in full force and effect.
                You may not assign this EULA, and any assignment of this EULA by you will be null
                and void. Cova Inc., Cova Inc. (stylized), the "in" logos, and other Cova Inc.-owned
                logos and names are trademarks of Cova Inc. and its affiliates. You agree not to
                display or use these trademarks in any manner without Cova Inc.'s prior, written
                permission. The section titles and numbering of this EULA are displayed for
                convenience and have no legal effect.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
        </ul>
      </div>
      <FooterComponent />
    </div>
  );
};

export default EulaPage;

import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./auth.scss";
import { HeadexComponent } from "../../components/header/headex";
import { FooterComponent } from "../../components/footer";
import { ComingSoonComponent } from "../../components/comingsoon";
import { Helmet } from "react-helmet";

export const TermsHeaderComponent = (props: any) => (
  <h2 className="terms_head" {...props}>
    {props.children}
  </h2>
);

export const TermsTextBodyComponent = (props: any) => (
  <div className="terms_body" {...props}>
    {props.children}
  </div>
);

const TermsListBodyComponent = (props: any) => (
  <ul className="terms_body" {...props}>
    {props.children}
  </ul>
);

export const TermsContainer = (props: any) =>
  props.security ? (
    <div className="terms_container">{props.children}</div>
  ) : (
    <li className="terms_container">{props.children}</li>
  );

const TermsandConditionPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="authpage no-border">
      <Helmet>
        <meta name="description" content="Learn more about Cova's term and conditions" />
        <title>Terms and Conditions</title>
        <meta property="og:title" content="Terms and Conditions" />
        <meta property="og:description" content="Learn more about Cova's term and conditions" />
        <meta property="og:url" content="https://www.getcova.com/terms-and-condition" />
      </Helmet>
      <HeadexComponent />
      <div className="terms_page">
        <div className="terms_intro">
          <div>
            <div className="terms_container">
              <h1>
                <span>Cova</span>&nbsp;<span>Terms</span>
              </h1>
              <p>
                Please read these Terms of Use (“Terms”), which set forth the legally binding terms
                and conditions between you and Cova Inc. (“Cova”). It governs your access to and the
                use of the Platform and all or any related services (collectively referred to as the
                “Service”) offered by Cova.
                <br />
                <br />
                Our collection and use of personal information in connection with your access to and
                use of the Service is described in our <Link to="/privacy">Privacy Policy</Link>.
                <br />
                <br />
                Your access to and use of the Service is conditioned on your acceptance of and
                compliance with these Terms. These Terms apply to all visitors, users and others who
                access or use the Platform.
                <br />
                <br />
                By accessing or using the Service you agree to be bound by these Terms. If you
                disagree with any part of the terms then you may not access the Service.
              </p>
            </div>
          </div>
        </div>
        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent>Definitions</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>In these Terms,</p>
              <br />
              <p>
                “Account” means unique personified account registered in the name of the User and
                which contains details of the User’s assets, transactions and operations on the
                Platform. Account holders and visitors to the website are referred to collectively
                as “Users.”
              </p>
              <br />
              <p>
                “Content” refers to content such as texts, mages, or other information posted,
                uploaded, linked to or made available by Users.
              </p>
              <br />
              <p>
                “Feedback” means any feedback, innovations or suggestions sent by Users regarding
                the attributes, performance or features of the Service.
              </p>
              <br />
              <p>
                “Free Plan” refers to a limited time that may be free when purchasing a
                subscription.
              </p>
              <br />
              <p>
                “Platform” refers to Cova’s application (“App”) and Website “Service(s)” refers to
                all products and services provided to you by Cova.
              </p>
              <br />
              <p>
                “Subscription” refers to access to the Service which is offered to Users on a
                subscription basis.
              </p>
              <br />
              <p>
                “Website” refers to the website for the services rendered by Cova which can be found
                at https://www.getcova.com/.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Acceptance of Terms</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                The Service is offered subject to the acceptance of all the terms and conditions
                contained in these Terms and all other operating rules, policies, and procedures
                that may be published on the Website by Cova, which are incorporated by reference,
                including operating rules, policies, and procedures of third-party service providers
                to the Website that are referenced herein. These Terms apply to every user of the
                Service.
              </li>
              <br />
              <li>
                Cova reserves the right, at its sole discretion, to modify or replace these Terms
                from time to time by posting the updated terms on the Website. It is your
                responsibility to check the Terms periodically for changes. If you object to any
                such changes, your sole recourse will be to cease using the Service. Your continued
                use of the Service following the posting of any changes to the Terms will indicate
                your acknowledgement of such changes and agreement to be bound by the terms and
                conditions of such changes.
              </li>
              <br />
              <li>
                Cova reserves the right to change, suspend, or discontinue the Service (including,
                but not limited to, the availability of any feature, database, or content) at any
                time for any reason. Cova may also impose limits on certain features and Services or
                restrict your access to parts of or the entire Website without notice or liability.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Scope of Cova’s Services</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                Cova is a technology company that allows Users to integrate their assets on one
                platform and notify next of kin/beneficiaries in the event of death or other
                unforeseen circumstance. Through the Platform, Users can track their wealth and
                ensure that their assets are claimed by their next of kin/beneficiaries.
              </li>
              <br />
              <li>
                Access to the Platform, or certain features of the Platform is subject to certain
                conditions or requirements, such as signing up for an account, completing a
                verification process, subscribing for the Service and other eligibility criteria as
                may be determined by Cova.
              </li>
              <br />
              <li>
                You must be at least 18 years old and able to enter into legally binding contracts
                to access and use the Services. By accessing or using the Services, you represent
                and warrant that you are 18 or older and have the legal capacity and authority to
                enter into a contract.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Accounts</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                To use and access the Service, all Users must register an account (“Account”).
              </li>
              <br />
              <li>You can register an Account using an email address and creating a password.</li>
              <br />
              <li>
                If you are registering an Account for a company or other legal entity, you represent
                and warrant that you have the authority to legally bind that entity and grant us all
                permissions and licenses provided in these Terms.
              </li>
              <br />
              <li>
                Accounts opened on the Platform can only be used by the person in whose name they
                are registered under. The User is granted an exclusive and non- assignable right to
                the use of and to access the Account and it is the User’s responsibility to ensure
                that no other third-party gains access to and/or trades through the Account assigned
                to the User.
              </li>
              <br />
              <li>
                You must provide accurate, current and complete information during the registration
                process and keep the information on your Account up-to-date at all times.
              </li>
              <br />
              <li>
                You are responsible for safeguarding the password that you use to access the
                Platform and for any activities or actions under your password, whether your
                password is with our Service or a third-party service.
              </li>
              <br />
              <li>
                You agree not to disclose your password to any third party. You must notify us
                immediately upon becoming aware of any breach of security or unauthorized use of
                your account.
              </li>
              <br />
              <li>
                You may not use as a username the name of another person or entity or that is not
                lawfully available for use, a name or trademark that is subject to any rights of
                another person or entity other than you without appropriate authorization, or a name
                that is otherwise offensive, vulgar or obscene.
              </li>
              <br />
              <li>
                You are liable for any and all activities conducted through your Account, unless
                such activities are not authorized by you and you are not otherwise negligent (such
                as failing to report the unauthorized use or loss of your credentials).
              </li>
              <br />
              <li>
                You agree to strictly observe the security and authentication procedures of the
                Platform and you will log out from the Platform at the end of every visit.
              </li>
              <br />
              <li>
                When you create an account with us, you must provide us with information that is
                accurate, complete, and current at all times. Failure to do so constitutes a breach
                of these Terms, which may result in the immediate termination of your Account.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Users’ Representations &amp; Warranties</TermsHeaderComponent>
            <TermsListBodyComponent>
              <p>
                Representations and warranties are statements and promises made by you to Cova,
                which we rely on as being accurate in our dealings with you. You make the following
                representations and warranties to us at the time of agreeing to these Terms and
                every time you use the Platform;
              </p>
              <br />
              <li>You are over the age of 18 years;</li>
              <br />
              <li>
                You are of sound mind and have the capacity to enter into a legally binding
                contract;
              </li>
              <br />
              <li>
                All personal information that you provide about yourself is accurate and true to the
                best of your knowledge;
              </li>
              <br />
              <li>
                All information you provide about your company is accurate and true to the best of
                your knowledge;
              </li>
              <br />
              <li>You have carefully considered the risks involved in using the Service;</li>
              <br />
              <li>
                If you are registering or accepting these Terms on behalf of a legal entity such as
                a company, trust or partnership, you are legally authorised to do so and we may
                request evidence of such legal entitlement (by way of a copy of any document which
                shows the valid and subsisting authorisation);
              </li>
              <br />
              <li>
                You shall be solely responsible for the use of the Services you subscribe fo,
                including the quality and integrity of any data and other information made available
                to us by you through the use of the Services under these Terms
              </li>
              <br />
              <li>
                You shall use the Services only in accordance with these Terms, our Privacy Policy,
                and applicable law or regulation;
              </li>
              <br />
              <li>
                You shall obtain such professional advice as is appropriate to protect your
                interests, including legal, tax, accounting and other advice; and
              </li>
              <br />
              <li>
                You are not breaching any laws or regulations that are applicable to you or any
                company, trust or partnership upon whose instructions you are acting.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Fees and Payment Terms </TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                Our Services are provided under a monthly or annual plan. We may also offer a
                Subscription under a free plan for a limited period of time.
              </li>
              <br />
              <li>
                If you use the Service under the free plan, you will not be charged by Cova until
                the free plan expires.{" "}
              </li>
              <br />
              <li>
                When you sign up for a paid monthly or annual plan, you agree to a recurring
                billing, starting on the date you sign up. Billing occurs on the same day each
                month, based on the date that you subscribed for the plan.
              </li>
              <br />
              <li>
                Should you wish to do so, you may cancel your Subscription and you may choose to do
                so through your Account settings.
              </li>
              <br />
              <li>
                Cova reserves the right to change the subscription fees at any time and in its sole
                discretion. You will be notified of such change and the change will take effect at
                the expiration of the current subscription period.
              </li>
              <br />
              <li>
                Subject to your Subscription, you agree to pay fees in accordance with the
                applicable rates available on our Website.
              </li>
              <br />
              <li>
                Subject to the requirements of the law, Cova will not refund paid subscription fees.
                Cova may however consider limited refund requests on a case-by-case basis.
              </li>
              <br />
              <li>
                You will notify us in writing in the event you dispute any fees paid or payable by
                you under these Terms. If you dispute any fees, you agree to act reasonably and in
                good faith and will cooperate diligently with us to resolve the dispute. You will
                provide such notice to us within 30 (thirty) working days of the date we bill you
                for such fees that are in dispute and the parties will work together to resolve the
                dispute promptly.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Credit Card and Payments</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>Payments for our Services can be made with your debit and credit cards.</li>
              <br />
              <li>
                You agree to provide us with your valid debit or credit card (“card”) information
                and authorize us to deduct your charges against that card.{" "}
              </li>
              <br />
              <li>
                Where your card expires, you agree to replace your billing information with the
                information of a valid card. You acknowledge and agree that we’re authorized to
                deduct any charges on your account against the new card.
              </li>
              <br />
              <li>
                Anyone using a card represents and warrants that they are authorized to use that
                card, and that all charges may be billed to that card and won’t be rejected. Where
                we are unable to process your card order, we will contact you by email and may
                suspend our Services to you until your payment can be processed.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Intellectual Property</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                The Service and its original content, features and functionality are and will remain
                the exclusive property of Cova. Our trademarks may not be used in connection with
                any product or service without our prior written consent.
              </li>
              <br />
              <li>
                Cova enables Users to (i) create, upload, post, send, receive and store content,
                such as text, photos, audio, video, or other materials and information on our
                Platform (&quot;User Content&quot;); and (ii) access and view User Content and any
                content that we make available on or through the Platform, including our proprietary
                content and any content licensed or authorized for use by or through Cova from a
                third party (&quot;Cova’s Content&quot; and together with User Content,
                &quot;Collective Content&quot;).
              </li>
              <br />
              <li>
                You will not use, copy, adapt, modify, prepare derivative works of, distribute,
                license, sell, transfer, publicly display, publicly perform, transmit, broadcast or
                otherwise exploit the Website or Collective Content, except to the extent that you
                are the legal owner of certain User Content or as expressly permitted in these
                Terms. No licenses or rights are granted to you by implication or otherwise under
                any intellectual property rights owned or controlled by Cova or its licensors,
                except for the licenses and rights expressly granted in these Terms.
              </li>
              <br />
              <li>
                Subject to your compliance with these Terms, Cova grants you a limited, non-
                exclusive, non-sub licensable, revocable, non-transferable license to access and
                view any Collective Content made available on or through the website and accessible
                to you, solely for your personal and non-commercial use.
              </li>
              <br />
              <li>
                Users shall not directly or indirectly: (i) decipher, decompile, disassemble,
                reverse engineer, or otherwise attempt to derive any source code or underlying ideas
                or algorithms of any part of the Service, except to the extent applicable laws
                specifically prohibit such restriction; (ii) modify, translate, or otherwise create
                derivative works of any part of the Service; or (iii) copy, rent, lease, distribute,
                or otherwise transfer any of the rights that you receive hereunder.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Prohibited Activities</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                You shall not use the Services for any purpose that is prohibited by these Terms.
                You are responsible for all of your activity in connection with the Services.
                Violation of our rules may result in the termination and cancellation of your
                account. You acknowledge and agree that we may terminate any Account at any time for
                any reason (including, but not limited to, our independent assessment or the receipt
                of claims or allegations from third parties or authorities).
              </li>
              <li>Users shall not utilize the Website or the Services for any illegal purpose.</li>
              <li>
                You are solely responsible for compliance with any and all laws, rules, regulations,
                and obligations that may apply to your use of the Services. In connection with your
                use of the Website, you will not and will not assist or enable others to:
                <ul className="disc">
                  <li>
                    Breach or circumvent any applicable laws or regulations, agreements with
                    third-parties, third-party rights, or our Terms;
                  </li>
                  <li>
                    Use the Platform or Collective Content for any commercial or other purposes that
                    are not expressly permitted by these Terms or in a manner that falsely implies
                    endorsement, partnership or otherwise misleads others as to your affiliation
                    with Cova;
                  </li>
                  <li>
                    Copy, store or otherwise access or use any information, including personally
                    identifiable information about any other User, contained on the Website in any
                    way that is inconsistent with Cova’s Privacy Policy or these Terms or that
                    otherwise violates the privacy rights of Users or third parties;
                  </li>
                  <li>
                    Use the Platform in connection with the distribution of unsolicited commercial
                    messages (&quot;spam&quot;) or for the distribution of content that violate any
                    laws;
                  </li>
                  <li>
                    Use the Platform with the intention to circumvent any Subscription Fees or for
                    any other reason;
                  </li>
                  <li>
                    Discriminate against or harass anyone on the basis of race, tribe, origin,
                    religion, gender, physical or mental disability, medical condition, marital
                    status, or age, or otherwise engage in any abusive or disruptive behaviour;
                  </li>
                  <li>
                    Use, display, mirror or frame the Platform or Collective Content, or any
                    individual element within the Platform, Cova’s name, any trademark, logo or
                    other proprietary information belonging to Cova, or the layout and design of any
                    page or form contained on a page in the Platform, without Cova’s express written
                    consent;
                  </li>
                  <li>
                    Dilute, tarnish or otherwise harm Cova’s brand in any way, including through
                    unauthorized use of Collective Content, registering and/or using derivative
                    terms in domain names, trade names, trademarks or other source identifiers, or
                    registering and/or using domains names, trade names, trademarks or other source
                    identifiers that closely imitate or are confusingly similar to Cova’s domains,
                    trademarks, taglines, promotional campaigns or Collective Content;
                  </li>
                  <li>
                    Use any robots, spider, crawler, scraper or other automated means or processes
                    to access, collect data or other content from or otherwise interact with the
                    Platform for any purpose;
                  </li>
                  <li>
                    Avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to
                    circumvent any technological measure implemented by Cova or any of Cova’s
                    service providers or any other third party to protect the Platform;
                  </li>
                  <li>
                    Attempt to decipher, decompile, disassemble or reverse engineer any of the
                    software used to provide the Platform;
                  </li>
                  <li>
                    Take any action that damages or adversely affects, or could damage or adversely
                    affect the performance or proper functioning of the Platform;
                  </li>
                  <li>
                    Violate or infringe anyone else’s rights or otherwise cause harm to anyone.
                  </li>
                </ul>
              </li>
              <li>
                You acknowledge that Cova has no obligation to monitor the access to or use of the
                Platform by any User or to review, disable access to, or edit any User Content, but
                has the right to do so to.
                <ul className="roman">
                  <li>
                    Operate, secure and improve the Platform (including without limitation for fraud
                    prevention, risk assessment, investigation and customer support purposes);{" "}
                  </li>
                  <li>Ensure Users’ compliance with these Terms; </li>
                  <li>
                    Comply with applicable law or the order or requirement of a court, law
                    enforcement or other administrative agency or governmental body;{" "}
                  </li>
                  <li>
                    Respond to User Content that it determines is harmful or objectionable; or{" "}
                  </li>
                  <li>
                    As otherwise set forth in these Terms. Users agree to cooperate with and assist
                    Cova in good faith, and to provide Cova with such information and take such
                    actions as may be reasonably requested by Cova with respect to any investigation
                    undertaken by Cova or a representative of Cova regarding the use or abuse of the
                    Platform.
                  </li>
                </ul>
              </li>
              <li>
                If you feel that any User you interact with, whether online or in person, is acting
                or has acted inappropriately, including but not limited to anyone who
                <ul className="roman">
                  <li>Engages in offensive, violent or sexually inappropriate behaviour,</li>
                  <li>You suspect of stealing from you, or</li>
                  <li>
                    Engages in any other disturbing conduct, you should immediately report such
                    person to the appropriate authorities and then to Cova by contacting us with
                    your police station and report number (if available); provided that your report
                    will not obligate us to take any action beyond that required by law (if any) or
                    cause us to incur any liability to you.
                  </li>
                </ul>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Links to Other Websites</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                The Platform may contain links to third-party websites or services that are not
                owned or controlled by Cova.
              </li>
              <li>
                Cova has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third-party websites or services. You further
                acknowledge and agree that Cova shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be caused by or in
                connection with use of or reliance on any such content, goods or services available
                on or through any such websites or services. We strongly advise you to read the
                terms and conditions and privacy policies of any third- party websites or services
                that you visit.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Communications</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                By registering an Account on the Platform, you hereby consent to receive electronic
                communications from Cova.  These communications may involve sending emails to the
                email address you provided when you registered your Account, or delivering
                electronic communications via your Account.  These communications are to foster your
                relationship with us.  You agree that any communications we send to you
                electronically, will satisfy any legal requirements for the delivery of
                communications in writing or other appropriate methods.  If you no longer want to
                receive certain communications via email, please click on the Unsubscribe link
                contained in any email.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Termination</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                We may terminate or suspend your Account immediately, without prior notice or
                liability, for any reason whatsoever, including without limitation if you breach
                these Terms.
              </li>
              <li>
                Upon termination, your right to use the Platform and all our Services will
                immediately cease. If you wish to terminate your Account, you may simply discontinue
                using the Platform.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Indemnity</TermsHeaderComponent>
            <TermsListBodyComponent>
              <p>
                You agree to indemnify and hold harmless Cova, its affiliates and subsidiaries, its
                officers, directors, employees and agents, against all liabilities, costs, expenses,
                damages and losses (including any direct, indirect or consequential losses, loss of
                profit, loss of reputation and all interest penalties and legal and other reasonable
                professional costs and expenses) suffered or incurred as a result of:
              </p>
              <br />
              <li>Your fraudulent or illegal use of the Platform;</li>
              <li>Your negligence or any default by you of any of these Terms;</li>
              <li>
                Any inaccurate or incomplete information that you have knowingly provided to us;
              </li>
              <li>
                Your allowing any other person to access your account either with your permission or
                as a result of your failure to keep your username and password private;
              </li>
              <li>
                Any service that you have offered, whether with or without our permission, to
                another third-party using Cova’s Services;
              </li>
              <li>
                Any claim made against you for actual or alleged infringement of Cova’s intellectual
                property rights or any actual or alleged infringement of a third party’s
                intellectual property rights arising out of or in connection with the Services or
                your use of the Platform.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Limitation of Liability</TermsHeaderComponent>
            <TermsListBodyComponent>
              <p>
                In no event shall Engage, its directors, employees, partners, agents, suppliers, or
                affiliates, be liable for any indirect, incidental, special, consequential or
                punitive damages, including without limitation, loss of profits, data, use,
                goodwill, or other intangible losses, resulting from:
              </p>
              <br />
              <li>Your use of the Platform or your inability to use the Platform;</li>
              <li>Any conduct or content of any third party on the Platform;</li>
              <li>
                Any unauthorized access, use or alteration of your transmissions or content, whether
                based on warranty, contract, tort (including negligence) or any other legal theory,
                whether or not we have been informed of the possibility of such damage, and even if
                a remedy set forth herein is found to have failed of its essential purpose;
              </li>
              <li>Any legal proceedings between the you and any third parties</li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Disclaimer</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                Your use of the Platform is at your sole risk. The Platform is provided on an “AS
                IS” and “AS AVAILABLE” basis. The Platform is provided without warranties of any
                kind, whether express or implied, including, but not limited to, implied warranties
                of merchantability, fitness for a particular purpose, non- infringement or course of
                performance.
              </li>
              <li>
                Cova, its subsidiaries, affiliates, and its licensors do not warrant that a) the
                Platform will function uninterrupted, secure or available at any particular time or
                location; b) any errors or defects will be corrected; c) the Platform is free of
                viruses or other harmful components; or d) the results of using the Platform will
                meet your requirements.
              </li>
              <li>
                Cova, its subsidiaries, affiliates, and licensors shall not be liable for any
                technical or other difficulties which may result in failure to obtain data, loss of
                data, the untimeliness, inaccuracy, deletion, non-delivery or failure to store any
                User Content, personalization settings, or other service interruptions.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Governing Law</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                These Terms shall be governed and construed in accordance with the laws of the State
                of Delaware, without regard to its conflict of law provisions. Your use of the
                Service may also be subject to other local, state, national or international laws.
              </li>
              <li>
                Our failure to enforce any right or provision of these Terms will not be considered
                a waiver of those rights. If any provision of these Terms is held to be invalid or
                unenforceable by a court, the remaining provisions of these Terms will remain in
                effect. These Terms constitute the entire agreement between us regarding our
                Service, and supersede and replace any prior agreements we might have between us
                regarding the Service.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Dispute Resolution</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                Any disputes arising under or in connection with the validity, interpretation and
                performance of these Terms between Cova and any third parties shall initially be
                resolved amicably by the parties through negotiation within 30 (thirty) days.
              </li>
              <li>
                Where the parties are unable to resolve the dispute amicably, the parties agree that
                any legal action or proceeding relating to this Agreement may be instituted in a
                state or federal court in Delaware, and agree to submit to the jurisdiction of the
                court and agree that venue is proper in, these courts in any such action or
                proceeding.
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Feedback</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                We welcome and encourage you to provide feedback, comments and suggestions for
                improvements to our Website or Services. You may submit your feedback by emailing{" "}
                <a href="mailto:support@getcova.com">support@getcova.com</a>. Any feedback you
                submit to us will be considered non-confidential and non-proprietary to you. By
                submitting any feedback to us, you grant us a non-exclusive, worldwide,
                royalty-free, irrevocable, sub-licensable, perpetual license to use and publish
                those ideas and materials for any purpose, without compensation to you.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Changes to Terms &amp; Conditions</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Cova reserves the right, in its sole discretion, to change the Terms under which the
                Service is offered. The most current version of the Terms will supersede all
                previous versions. Cova encourages you to periodically review the Terms to stay
                informed of our updates.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <TermsContainer>
            <TermsHeaderComponent>Contact Us</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                If you have any questions about these Terms of Use, please contact us at
                support@getcova.com
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>
          <div className="terms_container">
            <TermsTextBodyComponent>
              <p>This document was last updated on 18th of April, 2021</p>
            </TermsTextBodyComponent>
          </div>
        </ul>
        <ComingSoonComponent />
      </div>
      <FooterComponent />
    </div>
  );
};

export default TermsandConditionPage;

import { useState } from "react";
import "./frequentlyasked.scss";
import { ReactComponent as BlueEdge } from "../../assets/svg/child-blue-edge.svg";
import Child from "../../assets/img/child-image.png";

export function FrequentlyAskedComponent() {
  const [selectedFAQ, setSelectedFAQ] = useState(1);

  const handleFaqChange = (item: number) => {
    setSelectedFAQ(item);
  };

  return (
    <div className="frequently-asked" id="faqs">
      <div className="frequently-asked-wrapper">
        <div className="title-f">Frequently Asked Questions</div>
        <div className="frequently-asked-content">
          <div className="faqs-container" data-aos="fade-up" data-aos-duration="1000">
            <div
              className={`faq-item ${selectedFAQ === 1 ? "selected" : ""}`}
              onClick={() => {
                handleFaqChange(1);
              }}
            >
              <div className="faq-title">
                How does Cova transfer my portfolio information to my beneficiary?
              </div>
              {selectedFAQ === 1 && (
                <div className="faq-desc">
                  If you stop being active on Cova. Cova will send emails to remind you to click a
                  link as proof of life. If you don’t click the proof of life link after a certain
                  number of days, Cova will email a Zip folder containing your Cova portfolio
                  information to your beneficiary. You can set your transfer protocol and the number
                  of active days required.
                </div>
              )}
            </div>

            <div
              className={`faq-item ${selectedFAQ === 2 ? "selected" : ""}`}
              onClick={() => {
                handleFaqChange(2);
              }}
            >
              <div className="faq-title">
                I have a will or already have a living trust, do I still need Cova?  
              </div>
              {selectedFAQ === 2 && (
                <div className="faq-desc">
                  Yes. Trust and wills are legal documents whose primary purpose is to be enforced
                  in a court of law. Cova gives you a dashboard to track your assets in real-time,
                  measure your net worth and transfer the information to your loved ones in the
                  event of an eventuality. It is your smart asset register.
                </div>
              )}
            </div>

            <div
              className={`faq-item ${selectedFAQ === 3 ? "selected" : ""}`}
              onClick={() => {
                handleFaqChange(3);
              }}
            >
              <div className="faq-title">How secure is Cova? </div>
              {selectedFAQ === 3 && (
                <div className="faq-desc">
                  We understand that you will be storing your sensitive data on Cova and protecting
                  it is our highest priority. We use bank-grade encryption and security protocols to
                  keep your asset data protected. All communications between you and Cova are
                  encrypted via SSL using 2048-bit certificates, and we require SSL on all
                  communications. All your data is stored within Cova’s systems using AES-256
                  encryption with a uniquely derived key for each user as recommended by NIST
                  Special Publication 800-132. No one at Cova sees your portfolio data.
                </div>
              )}
            </div>
            <div
              className={`faq-item ${selectedFAQ === 4 ? "selected" : ""}`}
              onClick={() => {
                handleFaqChange(4);
              }}
            >
              <div className="faq-title">
                What types of asset classes can I add/track with Cova? 
              </div>
              {selectedFAQ === 4 && (
                <div className="faq-desc">
                  Everything. Cova allows you to safely connect bank and investment apps, Robo
                  Investors Cryptocurrency wallets, stocks, alternative assets, startup assets,
                  traditional assets and track your net worth in real-time while ensuring your loved
                  ones will find the assets in the event of an eventuality.
                </div>
              )}
            </div>
            <div
              className={`faq-item ${selectedFAQ === 5 ? "selected" : ""}`}
              onClick={() => {
                handleFaqChange(5);
              }}
            >
              <div className="faq-title">What does Beneficiary and Trusted Helper mean?</div>
              {selectedFAQ === 5 && (
                <div className="faq-desc">
                  Your Cova portfolio beneficiary is the person you have chosen to safely transfer
                  up-to-date information about your wealth portfolio in the event of eventualities.
                  Your Trusted Helper is someone you trust to help your beneficiary make the best
                  use of the information about your wealth in case your beneficiary is not
                  reachable, someone you are certain will have access to his email and phone and
                  will surely take action favor of you and your beneficiary. Usually, a Trusted
                  Helper is someone you select in case Cova is unable to reach your beneficiary.
                </div>
              )}
            </div>
          </div>

          <div className="frequently-asked-image">
            <img src={Child} alt="" />
            <BlueEdge className="blue-edge" />
          </div>
        </div>
      </div>
    </div>
  );
}

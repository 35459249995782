import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeadexComponent } from "../../components/header/headex";
import { FooterComponent } from "../../components/footer";
import { Testimonials } from "../../components/testimonials";
import { ReactComponent as CheckIcon } from "../../assets/svg/check-icon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/cancel.svg";
import { ReactComponent as StarsBlack } from "../../assets/svg/stars-black.svg";
import "./pricing.scss";
import { Helmet } from "react-helmet";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";

export default function Pricing() {
  const [yearlySelected, setYearlySelected] = useState(true);
  const [paidExpanded, setPaidExpanded] = useState(false);
  const [freeExpanded, setFreeExpanded] = useState(false);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pricing</title>
        <meta name="description" content="Find Your Perfect Pricing Plan As Low as $10 per Month" />
        <meta property="og:title" content="Pricing" />
        <meta
          property="og:description"
          content="Find Your Perfect Pricing Plan As Low as $10 per Month"
        />
      </Helmet>
      <HeadexComponent />
      <div className="pricing-page-container">
        <div className="pricing-hero">
          <div className="pricing-hero-wrapper" data-aos="fade-up" data-aos-duration="1000">
            <div className="pricing-hero-title">Pricing</div>
            <div className="pricing-hero-desc">
              Find a pricing that works for you. Gain insight on your portfolio and take full
              control of your wealth - all in one place!
            </div>
          </div>

          <div className="pricing-main" data-aos="fade-up" data-aos-duration="1000">
            <div className="pricing-plans">
              <div className="pricing-info">
                <div className="pricing-switch">
                  <div
                    className={`monthlyp ${!yearlySelected ? "selected" : ""}`}
                    onClick={() => {
                      setYearlySelected(false);
                    }}
                  >
                    Monthly
                  </div>
                  <div
                    className={`yearlyp ${yearlySelected ? "selected" : ""}`}
                    onClick={() => {
                      setYearlySelected(true);
                    }}
                  >
                    Yearly (save 20%)
                  </div>
                </div>

                <div className="topp">
                  <div className="t-left">Cova Premium</div>

                  <div className="t-right">{yearlySelected ? "$100" : "$10"}</div>
                </div>

                <Link to="/signup">
                  <div className="pricing-button">Start your 14 days free trial</div>
                </Link>

                <div className="middlep">
                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Sync balance directly from your finance and investment Apps
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Real-time tracking of over 150,000 global stocks.
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Track over 8000 crypto tickers, wallets, and exchanges in real-time.
                    </div>
                  </div>

                  <div className={`expand-group ${paidExpanded ? "" : "e-g-hide"}`}>
                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Connect over 11,000 banks and brokerage accounts
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Automated Net worth Tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Automated liabilities tracking; credit cards, loans & more
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Manage portfolio beneficiaries; create a deadman’s switch
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Unlimited storage space for vital documents
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Monthly reports on portfolio and asset history.
                      </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Data is live and current.</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setPaidExpanded(!paidExpanded);
                    }}
                    className="expand-card click"
                  >
                    View {paidExpanded ? "Less" : "All"}{" "}
                    <ArrowDown
                      className={`arrow-d color-white  ${paidExpanded ? "rotate213" : ""}`}
                    />
                  </div>
                </div>
              </div>

              <div className="pricing-info basic">
                <div className="premium-access">
                  <StarsBlack /> <span>Free Premium access for 14 days</span>
                </div>

                <div className="topp">
                  <div className="t-left">Cova Free Plan</div>

                  <div className="t-right">$0</div>
                </div>

                <Link to="/signup">
                  <div className="pricing-button basic">Continue on Free Plan</div>
                </Link>
                <div className="b-text">*no credit card required</div>

                <div className="middlep">
                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">Track Your Net Worth </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Manual assets tracking; no real-time updates.
                    </div>
                  </div>

                  <div className="middlep-item">
                    <div className="check-image">
                      <CheckIcon />
                    </div>
                    <div className="middlep-text">
                      Manual liabilities tracking; credit cards, loans, etc.
                    </div>
                  </div>

                  <div className={`expand-group ${freeExpanded ? "" : "e-g-hide"}`}>
                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">Assign a beneficiary to your portfolio</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">500MB storage space for vital documents</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CheckIcon />
                      </div>
                      <div className="middlep-text">
                        Manage portfolio beneficiaries; create a deadman’s switch
                      </div>
                    </div>

                    <div className="m-p-divider" />

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No cryptocurrency exchange/wallet tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No real-time stock tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">No banks or brokerage accounts tracking</div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">Cannot connect finance & investments apps </div>
                    </div>

                    <div className="middlep-item">
                      <div className="check-image">
                        <CancelIcon />
                      </div>
                      <div className="middlep-text">Data is not in real-time</div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setFreeExpanded(!freeExpanded);
                    }}
                    className="expand-card text-black click"
                  >
                    View {freeExpanded ? "Less" : "All"}{" "}
                    <ArrowDown className={`arrow-d ${freeExpanded ? "rotate213" : ""}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonials />

      <div className="footer-wrapper-p">
        <FooterComponent />
      </div>
    </div>
  );
}

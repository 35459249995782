import "./comingsoon.scss";
import Phone from "../../assets/img/animated-mockup.gif";
import GooglePlay from "../../assets/svg/download-app.svg";

export const ComingSoonComponent = () => {
  return (
    <>
      <div id="coming-soon" className="coming-soon">
        <div className="coming-soon-wrapper">
          <div className="coming-soon-1" data-aos="fade-up" data-aos-duration="1000">
            <p className="coming-soon-ready">Now live on mobile</p>
            <p className="coming-soon-start">
              Cova is now live on mobile with all of the features at your fingertips!
            </p>
            <div className="store-icons">
              <a href="https://getcova.app.link/download" target="_blank" rel="noreferrer">
                <img src={GooglePlay} alt="" />
              </a>
            </div>
          </div>
          <div className="coming-soon-2">
            <img src={Phone} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

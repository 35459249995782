import { useEffect, useState } from "react";
import { Authheader } from "../auth/authheader";
import PinInput from "react-pin-input";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Errornotf } from "../../components/notifications/errornotf";
import { Helmet } from "react-helmet";
import MainButton from "../../components/buttons/mainButton";
import { useContext } from "react";
import { LoaderContext } from "../../contexts/loader";
import "../auth/auth.scss";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { VERIFY_USER_EMAIL, RESEND_ACCOUNT_VERIFY_CODE } from "../../graphql/auth";
import useLocalStorage from "../../hooks/useLocalStorage";

export type VerifyAccountResponse = {
  accessToken: string;
  email: string;
  hasSmsEnabled: boolean;
  hasTotpEnabled: boolean;
  is2FAAuthenticated: boolean;
  refreshToken: string | null;
};

const Verifyemailpage = () => {
  const [pin, setPin] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [errorMessage, seterrorMessage] = useState<string | null>(null);
  const location = useLocation();
  const [emailshort, setemailshort] = useState("***@***.com");
  let navigate = useNavigate();
  let parseddata = queryString.parse(location.search);
  const [, setShowLoader] = useContext(LoaderContext);
  const [, setUserdata] = useLocalStorage("userdata", {} as VerifyAccountResponse);

  useEffect(() => {
    let r: any = parseddata?.email;
    if (r) {
      let br = r.split("@");
      let newEmail = br[0][0] + "***" + br[0][br[0].length - 1] + "@" + br[1];
      setemailshort(newEmail);
    } else {
      navigate("/signup");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verifyemail] = useMutation(VERIFY_USER_EMAIL, {
    onCompleted(data: { verifyAccount: VerifyAccountResponse }) {
      if (data.verifyAccount) {
        setUserdata(data.verifyAccount);
        // localStorage.setItem("formresponsetoken", data?.token);
        navigate(`/success/?email=${parseddata?.email}`);
      } else {
        seterrorMessage("Something went wrong.");
      }

      setShowLoader(false);
    },
    onError(error: any) {
      seterrorMessage(error.message ?? "Something went wrong.");
      setShowLoader(false);
    },
  });

  const [resendCode] = useMutation(RESEND_ACCOUNT_VERIFY_CODE, {
    onCompleted(data: any) {
      if (data.resendAccountVerifyCode) {
        toast.success("A new OTP has been sent to your email");
      } else {
        seterrorMessage("Something went wrong.");
      }

      setShowLoader(false);
    },
    onError(error: any) {
      seterrorMessage(error.message ?? "Something went wrong.");
      setShowLoader(false);
    },
  });

  let updatePin = (value: any) => {
    setPin(value);
  };

  let submit = () => {
    seterrorMessage(null);
    seterrormessage("");
    if (pin === "" || pin.length !== 6) {
      seterrormessage("Enter a valid pin");
      return;
    }

    setShowLoader(true);
    verifyemail({
      variables: {
        verifyInput: {
          code: pin,
          email: parseddata?.email,
        },
      },
    });
  };

  return (
    <div className="authpage">
      <Helmet>
        <title>Verify Your Email Address</title>
        <meta name="description" content="Verify your email address to access your Cova account" />
        <meta property="og:title" content="Verify Your Email Address" />
        <meta
          property="og:description"
          content="Verify your email address to access your Cova account"
        />
        <meta property="og:url" content={`${process.env.REACT_APP_HOME}verify-email`} />
      </Helmet>
      <Authheader noClick />
      <div className="forgotpassword">
        <div className="inner">
          <p className="h3-size emailsent">We Sent A Code To</p>
          <p className="h4-size">{emailshort}</p>

          <p className="p-normal message">
            Please enter the 6 digit verification code we sent to your email address. The code will
            expire in 5 minutes.
          </p>

          <Errornotf style={{ marginTop: "40px", marginBottom: "25px" }} message={errorMessage} />
          <div style={{ marginBottom: "40px" }} className="form-control">
            <PinInput
              type="numeric"
              inputMode="number"
              initialValue=""
              length={6}
              onComplete={(value, index) => {
                updatePin(value);
              }}
              onChange={(value, index) => {
                updatePin(value);
              }}
            />
            {errormessage ? (
              <small className="helper-text errormessage danger">{errormessage}</small>
            ) : null}
          </div>
          <div className="placecenter authbutton2 center">
            <MainButton click={submit} size="big" type="primary">
              <span className="text-nowrap">Verify my email address</span>
            </MainButton>
          </div>
          <div>
            <p className=" text-center">
              <span className="lighgrey p-normal">Didn't get a code?</span>{" "}
              <span
                onClick={() => {
                  setShowLoader(true);

                  resendCode({
                    variables: {
                      email: parseddata?.email,
                    },
                  });
                }}
                className="pointer primary-link p-semibold"
              >
                Resend Code{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verifyemailpage;

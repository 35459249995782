import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FooterComponent } from "../../components/footer";
import { HeadexComponent } from "../../components/header/headex";
import { GetStartedComponent } from "../../components/getstarted";
import { ReactComponent as Diamond } from "../../assets/svg/diamond-icon.svg";
import { ReactComponent as Bank } from "../../assets/svg/bank-icon.svg";
import { ReactComponent as Person } from "../../assets/svg/person-icon.svg";
import { ReactComponent as Document } from "../../assets/svg/document-icon.svg";
import { ReactComponent as BlueVector } from "../../assets/svg/blue-vector.svg";
import { ReactComponent as PinkVector } from "../../assets/svg/pink-vector.svg";
import { ReactComponent as USA } from "../../assets/svg/usa.svg";
import { ReactComponent as UK } from "../../assets/svg/uk.svg";
import { ReactComponent as SA } from "../../assets/svg/sa.svg";
import WhyCovaImage from "../../assets/img/why-cova-image.png";
import { Helmet } from "react-helmet";

import "./whycova.scss";

export default function Whycova() {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Why We Started Cova</title>
        <meta
          name="description"
          content="Our mission is to empower everyone to take complete control of their wealth."
        />
        <meta property="og:title" content="Why We Started Cova" />
        <meta
          property="og:description"
          content="Our mission is to empower everyone to take complete control of their wealth."
        />
      </Helmet>
      <HeadexComponent />
      <div className="why-cova">
        <div className="why-hero">
          <div className="why-hero-wrapper" data-aos="fade-up" data-aos-duration="1000">
            <div className="why-hero-text">
              <div className="why-hero-title">
                Our investments <br /> are more <span>diverse</span> and <span>fragmented</span>{" "}
                than ever
              </div>
              <div className="why-hero-desc">
                With alternative assets, brokerage apps, cryptocurrencies, cross border fintech
                apps, and fractional asset ownerships, it’s becoming more difficult to keep track of
                our investments without the fears losing track. That’s where Cova comes in.
              </div>
              <Link to="/signup" className="getstarted">
                Get started for free
              </Link>
            </div>
            <div className="why-hero-image">
              <img src={WhyCovaImage} alt="" />
            </div>
          </div>
        </div>

        <div className="why-metrics">
          <div className="why-metrics-wrapper">
            <div className="why-card-one">
              <div className="card-one-wrapper" data-aos="fade-up" data-aos-duration="1000">
                <PinkVector className="pink" />
                <BlueVector className="blue" />
                <span>
                  Cova gives you control of your wealth and ensures your assets never become
                  unclaimed assets in the event of an eventuality.
                </span>
              </div>
            </div>

            <div className="why-card-two">
              <div className="card-two-wrapper" data-aos="fade-up" data-aos-duration="1000">
                <div className="why-cards-row">
                  <div className="card-two-item">
                    <div className="item-amount">$80b</div>
                    <div className="item-desc">
                      Value of unclaimed assets in the United States
                      <USA className="flag" />
                    </div>
                  </div>
                  <div className="card-two-item">
                    <div className="item-amount">£50b</div>
                    <div className="item-desc">
                      Value of unclaimed assets in the United Kingdom
                      <UK className="flag" />
                    </div>
                  </div>
                </div>
                <div className="why-cards-row">
                  <div className="card-two-item">
                    <div className="item-amount">$4-$7b</div>
                    <div className="item-desc">
                      Value of unclaimed assets in South Africa
                      <SA className="flag" />
                    </div>
                  </div>
                  <div className="card-two-item">
                    <div className="item-amount">???</div>
                    <div className="item-desc">
                      The number is unknown for the rest of the world 🌎
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why-main">
          <div className="why-main-row">
            <div className="why-main-item" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-why">
                <Diamond />
              </div>
              <div className="title-why">Track everything you own</div>
              <div className="desc-why">
                It's hard to keep track of all your scattered Investments. Cova combines asset
                classes including traditional assets and digital cryptocurrencies not offered by
                platforms like Personal Capital, Mint & YNAB, or any other solutions out there.
              </div>
            </div>

            <div className="why-main-item" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-why">
                <Bank />
              </div>
              <div className="title-why">A better alternative to Google sheets</div>
              <div className="desc-why">
                Cova syncs balances from all your banks, wallets, and investments apps offering the
                best alternative to your Google & Excel sheets. Cova gives you a true picture of
                your investments.
              </div>
            </div>
          </div>

          <div className="why-main-row">
            <div className="why-main-item" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-why">
                <Person />
              </div>
              <div className="title-why">Be ready for all emergencies</div>
              <div className="desc-why">
                Cova’s beneficiary feature lets you set up a dead man switch that notifies your
                chosen family member of your portfolio data in the event you stopped being active on
                the app.
              </div>
            </div>

            <div className="why-main-item" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-why">
                <Document />
              </div>
              <div className="title-why">Manage crucial documents</div>
              <div className="desc-why">
                Cova comes with a secured vault where you can keep documents like Wills, Trusts, and
                other documents that are crucial to your assets.
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetStartedComponent theme="blue" />
      <FooterComponent />
    </div>
  );
}

import { useEffect, useState, useContext } from "react";
import "./auth.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo1.svg";
import { GoogleIcon } from "../../components/icons/googleicon";
import { Textinput } from "../../components/inputs/textinput";
import { TextinputwithLeftIcon } from "../../components/inputs/textinputwithlefticon";
import { useNavigate, useLocation } from "react-router-dom";
import { Errornotf } from "../../components/notifications/errornotf";
import { useForm, Controller } from "react-hook-form";
import { ClosedangerIcon } from "../../components/icons/closedanger";
import { MarksuccessIcon } from "../../components/icons/marksuccess";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import MainButton from "../../components/buttons/mainButton";
import axios from "axios";
import { LoaderContext } from "../../contexts/loader";
import { toast } from "react-toastify";
import Lock from "../../assets/svg/sign-up-lock.svg";
import User from "../../assets/img/user-img.png";
import Star from "../../assets/svg/star.svg";
import Typewriter from "typewriter-effect";
import useLocalStorage from "../../hooks/useLocalStorage";
import AlertCircle from "../../assets/svg/alert-circle.svg";
import QrScan from "../../assets/img/qrcode.png";
import { FETCH_GOOGLE_URL, LOGIN_WITH_GOOGLE_URL, SIGNUP_USER } from "../../graphql/auth";
import { useMutation } from "@apollo/client";
import { VerifyAccountResponse } from "../verify-email";

const SignUp = () => {
  const [ispassword, setispassword] = useState(true);
  const [errorMessage] = useState(null);
  const [, setShowLoader] = useContext(LoaderContext);
  const [collabStatus, setCollabStatus] = useLocalStorage("collab", false);
  const [resellerInviteEmail, setResellerInviteEmail] = useState<string>("");
  const [inviterId, setInviterId] = useLocalStorage("sec", "");
  const [email, setEmail] = useState<string>("");
  const [, setUserdata] = useLocalStorage("userdata", {} as VerifyAccountResponse);

  const location = useLocation();
  let navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ criteriaMode: "all", mode: "onChange" });

  const verifyResellerInvite = (email: string, otp: string) => {
    axios.post(
      `${process.env.REACT_APP_API}api/reseller/invite/verify`,
      { email, otp },
      {
        headers: {
          accept: "*/*",
        },
      }
    );
  };

  const [fetchgoogleurl] = useMutation(FETCH_GOOGLE_URL, {
    onCompleted(data: any) {
      window.location.href = data.signInWithGoogleUrl;
    },
    onError(error: any) {
      toast.error(error.message);
      setShowLoader(false);
    },
  });

  const [signinwithgoogle] = useMutation(LOGIN_WITH_GOOGLE_URL, {
    onCompleted(data: any) {
      setUserdata(data.signInWithGoogle);

      setShowLoader(false);
      navigate(`/success/?email=${data.signInWithGoogle.email}`);
    },
    onError(error: any) {
      toast.error(error.message ?? "Something went wrong.");
      setShowLoader(false);
    },
  });

  const [signup] = useMutation(SIGNUP_USER, {
    onCompleted(data: any) {
      navigate(`/verify-email/?email=${encodeURIComponent(email)}`);
      setShowLoader(false);
    },
    onError(error: any) {
      setShowLoader(false);
      toast.error(error.message ?? "Something went wrong.");
    },
  });

  const onSubmit = (data: any) => {
    localStorage.setItem("signupmethod", "form");
    setEmail(data.email);
    let requestdata = {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      password: data.password,
    };

    setShowLoader(true);
    signup({
      variables: {
        signUpInput: requestdata,
      },
    });
  };

  const changeinputField = () => {
    setispassword(!ispassword);
  };

  useEffect(() => {
    let { code } = queryString.parse(location.search);
    if (code) {
      setShowLoader(true);
      signinwithgoogle({
        variables: {
          code: typeof code === "string" ? decodeURI(code) : "",
          redirectUri: window.location.origin + "/signup",
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem("tsrc");

    if (window) {
      window.scrollTo(0, 0);
    }

    let parseddata: any = queryString.parse(location.search);

    if (parseddata.sec && parseddata.email && parseddata.mexed) {
      setResellerInviteEmail(parseddata.email);
      verifyResellerInvite(parseddata.email, parseddata.sec);
    } else if (parseddata.exed || parseddata.sec) {
      setCollabStatus(true);
      setInviterId(parseddata.sec);
    } else {
      localStorage.removeItem("collab");
      localStorage.removeItem("sec");
      setCollabStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googlesignin = () => {
    setShowLoader(true);
    fetchgoogleurl({
      variables: {
        redirectUri: window.location.origin + "/signup",
      },
    });
  };

  return (
    <div className="authpage signup">
      <Helmet>
        <title>Get Started | Secure Wealth Tracking & Portfolio Management.</title>
        <meta
          name="description"
          content="Create a free account. Cova helps you to organize your assets, track your investment, plan your estate, in one place."
        />
        <meta
          property="og:title"
          content="Get Started | Secure Wealth Tracking & Portfolio Management."
        />
        <meta
          property="og:description"
          content="Create a free account. Cova helps you to organize your assets, track your investment, plan your estate, in one place."
        />
        <meta property="og:url" content={`${process.env.REACT_APP_HOME}signup`} />
      </Helmet>

      <div className="block-small-screen">
        <h1 className="heading-small-screen">
          Organize your{" "}
          <span className="blue-text">
            <Typewriter
              options={{
                strings: [
                  "assets",
                  "bank accounts",
                  "wallets",
                  "stocks",
                  "real estate",
                  "art",
                  "documents",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </span>
        </h1>

        <p className="text-small-screen">
          Sign up to Cova for free. To track your assets, liabilities, and plan your estate.
        </p>
      </div>

      <div className="divideauthpage">
        <div className="firsthalf">
          <div className="authheader2">
            <div className="logocontainer">
              <Link to="/" className="no-underline">
                <div className="ttt">
                  <Logo />
                  {process.env.REACT_APP_DEPLOY_ENV === "beta" && (
                    <p className="beta-flag ">Beta</p>
                  )}
                </div>
              </Link>
            </div>
          </div>
          <div className="bottom">
            <div className="block">
              <h1 className="heading">
                Organize your{" "}
                <span className="blue-text">
                  <Typewriter
                    options={{
                      strings: [
                        "assets",
                        "bank accounts",
                        "wallets",
                        "stocks",
                        "real estate",
                        "art",
                        "documents",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </h1>

              <p className="text">
                Sign up to Cova for free. To track your assets, liabilities, and plan your estate.
              </p>

              <div className="secure-box">
                <img src={QrScan} alt="Qr Code" />
                <p className="secure-text">Available on mobile, scan to download</p>
              </div>
            </div>

            <div className="rate">
              <p className="rate-text">
                “Cova gives me a clear view of all finances and the peace of mind that nothing will
                fall within the cracks in the event of any of life's emergencies”
              </p>
              <div className="user-section">
                <img alt="" src={User} className="user" />

                <div className="star-section">
                  {[...Array(5)].map((_) => (
                    <img alt="" src={Star} key={Math.random()} className="star" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="secondhalf">
          <div className="signup-container">
            <div className="top-div">
              <h3 className="top-text">Sign Up for Free</h3>
              <a
                href={
                  collabStatus
                    ? `${process.env.REACT_APP_SIGN_LINK}signin/?exed=true&sec=${inviterId}`
                    : process.env.REACT_APP_SIGN_LINK
                }
                className="link-text no-underline"
              >
                Sign in
              </a>
            </div>
            {(collabStatus || resellerInviteEmail) && (
              <div className="collab-notification">
                <img src={AlertCircle} alt="alert circle" /> Please create an account{" "}
                {resellerInviteEmail
                  ? `with ${resellerInviteEmail}`
                  : "using the email you were invited with."}
              </div>
            )}
            <div className="google-btn-div">
              <button
                onClick={() => {
                  localStorage.setItem("signupmethod", "google");
                  googlesignin();
                }}
                className="google-btn"
              >
                <GoogleIcon />
                <span className="google-text">Sign Up with Google</span>
              </button>
            </div>

            <div className="or-div">
              <p className="or-text">Or</p>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Errornotf style={{ marginBottom: "20px" }} message={errorMessage} />
                <div className="grid-lay">
                  <div className="input-div">
                    <Controller
                      name="firstname"
                      defaultValue={null}
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Textinput
                          onChange={(e: any) => {
                            onChange(e.target.value);
                          }}
                          checked={value}
                          label="Firstname"
                          inputid="firstname"
                          type="text"
                          placeholder="John"
                          iserror={errors.firstname}
                          message={"This field is required"}
                        />
                      )}
                    />
                  </div>
                  <div className="input-div">
                    <Controller
                      name="lastname"
                      defaultValue={null}
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Textinput
                          onChange={(e: any) => {
                            onChange(e.target.value);
                          }}
                          checked={value}
                          label="Lastname"
                          inputid="lastname"
                          type="text"
                          placeholder="Doe"
                          iserror={errors.lastname}
                          message={"This field is required"}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="input-div">
                  <Controller
                    name="email"
                    defaultValue={null}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Textinput
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                        checked={value}
                        label="Email"
                        inputid="email"
                        type="email"
                        placeholder={resellerInviteEmail ? resellerInviteEmail : "name@email.com"}
                        iserror={errors.email}
                        message={"Please provide a correct email address."}
                      />
                    )}
                  />
                </div>
                <div className="input-div">
                  <Controller
                    name="password"
                    defaultValue={null}
                    rules={{
                      validate: {
                        isUpper: (value) => /[A-Z]/.test(value),
                        is8Digit: (value) => value && value.length >= 8,
                        isNumber: (value) => /\d/.test(value),
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextinputwithLeftIcon
                        onChange={(e: any) => {
                          onChange(e.target.value);
                        }}
                        checked={value}
                        type={ispassword ? "password" : "text"}
                        onclickicon={changeinputField}
                        label="Password"
                        inputid="password"
                        placeholder="8 characters, 1 upper case letter and 1 number."
                        iserror={errors.email}
                      />
                    )}
                  />
                  {errors.password ? (
                    <div className="">
                      <p className="err-deck-1">
                        {errors &&
                        errors.password &&
                        errors.password.types &&
                        errors.password.types.is8Digit ? (
                          <span className="mr-1">
                            <ClosedangerIcon />
                          </span>
                        ) : (
                          <span className="mr-1">
                            <MarksuccessIcon />
                          </span>
                        )}
                        <span>8 characters</span>
                      </p>
                      <p className="err-deck-1">
                        {errors &&
                        errors.password &&
                        errors.password.types &&
                        errors.password.types.isUpper ? (
                          <span className="mr-1">
                            <ClosedangerIcon />
                          </span>
                        ) : (
                          <span className="mr-1">
                            <MarksuccessIcon />
                          </span>
                        )}
                        <span>1 UPPER CASE letter</span>
                      </p>
                      <p className="err-deck-1">
                        {errors &&
                        errors.password &&
                        errors.password.types &&
                        errors.password.types.isNumber ? (
                          <span className="mr-1">
                            <ClosedangerIcon />
                          </span>
                        ) : (
                          <span className="mr-1">
                            <MarksuccessIcon />
                          </span>
                        )}
                        <span>1 number</span>
                      </p>
                    </div>
                  ) : null}
                </div>

                <div className="space-down">
                  <MainButton type="primary" size="big" extraClasses="move-right">
                    <span className="text-p1">Sign Up</span>
                  </MainButton>
                </div>
              </form>
            </div>
            <div className="term-div">
              <p className="term-text">
                By clicking Sign Up, I agree to the{" "}
                <span
                  onClick={() => {
                    navigate("/terms-and-condition");
                  }}
                  className="term-inner"
                >
                  Terms & Condition{" "}
                </span>
                and{" "}
                <span
                  onClick={() => {
                    navigate("/privacy");
                  }}
                  className="term-inner"
                >
                  Privacy Policy
                </span>
              </p>
            </div>
            <div className="term-div2">
              <p className="term-text">
                Already have an account?{" "}
                <span>
                  <a
                    className="term-inner"
                    href={
                      collabStatus
                        ? `${process.env.REACT_APP_SIGN_LINK}signin/?exed=true&sec=${inviterId}`
                        : process.env.REACT_APP_SIGN_LINK
                    }
                  >
                    Sign In
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="secure-box-small-screen">
        <img alt="secure lock" src={Lock} />
        <p className="secure-text-small-screen">
          Securely transfer your wealth records in case the unexpected happens.
        </p>
      </div>
    </div>
  );
};

export default SignUp;
